import * as React from "react";
import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import useClientCredentialsToken from "../../hooks/useClientCredentialsToken";
import { env } from "../../data/constants";

interface NavLink {
  path: string;
  label: string;
}

interface NavLinksProps {
  activeLink: string;
  handleLinkClick: (link: string) => void;
  links: NavLink[];
}

const NavLinks: React.FC<NavLinksProps> = ({ activeLink, handleLinkClick, links }) => {
  return (
    <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
      {links.map((link) => (
        <li className="nav-item" key={link.path}>
          <Link
            className={`nav-link fs-6 fw-medium text-hover-danger ${
              activeLink === link.path ? "text-danger" : ""
            }`}
            aria-current="page"
            to={link.path}
            onClick={() => handleLinkClick(link.path)}
          >
            {link.label}
          </Link>
        </li>
      ))}
    </ul>
  );
};

export default function PageHeader() {
  const location = useLocation();
  const [activeLink, setActiveLink] = useState<string>("/#home");
  const { error } = useClientCredentialsToken();
  console.log("error :", error);

  useEffect(() => {
    setActiveLink(location.hash ? location.pathname + location.hash : "/#home");
  }, [location.pathname, location.hash]);

  const handleLinkClick = (link: string) => {
    setActiveLink(link);
  };

  const links = [
    { path: "/#home", label: "Home" },
    { path: "/#about", label: "About" },
    { path: "/#features", label: "Features" },
    { path: "/#value", label: "Value" },
    { path: "/#faqs", label: "Faqs" },
    { path: "/opportunities", label: "Opportunities" },
  ];

  return (
    <nav className="navbar navbar-expand-lg bg-white fixed-top border-bottom">
      <div className="container">
        <Link className="navbar-brand" to="/#home">
          <img src={"logo.png"} alt="Black Logo" className="navbar-logo" />
        </Link>
        <button
          className="navbar-toggler border-0"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <NavLinks activeLink={activeLink} handleLinkClick={handleLinkClick} links={links} />
          <div className="d-flex justify-content-center align-items-center gap-3 ">
            <a
              target="_blank"
              rel="noreferrer"
              href={`${env.LoginUrl || "#"}`}
              className="btn btn-outline-danger fs-6"
            >
              Login
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href={`${env.LoginUrl || "#"}`}
              className="btn btn-danger fs-6"
            >
              Create Account
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
}