import * as React from "react";
import { Link } from "react-router-dom";
import OpportunityCard from "./OpportunityCard";
import { useEffect, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import { AUTH_TOKEN_KEY, env } from "../../data/constants";
import Skeleton from "@mui/material/Skeleton";
import CardHeader from "@mui/material/CardHeader";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

export default function OpportunitiesList() {
  const [jobs, setJobs] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [reloadAttempts, setReloadAttempts] = useState<number>(0);

  useEffect(() => {
    const fetchCalls = async () => {
      const accessToken = localStorage.getItem(AUTH_TOKEN_KEY) || "";
      const getFinancierCallsUrl = env.FinancierUrl
        ? `${env.FinancierUrl}/calls/`
        : "";
      try {
        const response = await fetch(getFinancierCallsUrl, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${accessToken}`,
          },
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();

        setJobs(data.result.calls); // Update state with the fetched data
        setIsLoading(false);
      } catch (error: any) {
        setError(error.message); // Handle errors
        setIsLoading(false);
      }
    };

    fetchCalls();
  }, [reloadAttempts]);

  if (isLoading) {
    return (
      <div className="text-center py-5 d-flex justify-content-evenly">
        {Array.from({ length: 3 }).map((_, index) => (
          <Card sx={{ maxWidth: 300, m: 2, width: 300 }} key={index}>
            <CardHeader
              avatar={
                <Skeleton
                  animation="wave"
                  variant="circular"
                  width={40}
                  height={40}
                />
              }
              action={null}
              title={
                <Skeleton
                  animation="wave"
                  height={10}
                  width="80%"
                  style={{ marginBottom: 6 }}
                />
              }
              subheader={<Skeleton animation="wave" height={10} width="40%" />}
            />
            <Skeleton
              sx={{ height: 190 }}
              animation="wave"
              variant="rectangular"
            />
            <CardContent>
              <React.Fragment>
                <Skeleton
                  animation="wave"
                  height={10}
                  style={{ marginBottom: 6 }}
                />
                <Skeleton animation="wave" height={10} width="80%" />
              </React.Fragment>
            </CardContent>
          </Card>
        ))}
      </div>
    );
  }

  if (error) {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center py-5">
        <img
          alt="page under maintenance"
          className=""
          src={`${process.env.PUBLIC_URL}/images/page-under-maintenance.svg`}
        />
        <h1 className="mt-3">Page Under Maintenance</h1>
        <p className="text-center w-50">
          We apologize for the inconvenience, but this page is currently
          undergoing maintenance.
        </p>
        {reloadAttempts < 3 && (
          <button
            className="btn btn-sm btn-danger d-flex align-items-center justify-content-center h-35"
            onClick={() => {
              setIsLoading(true);
              setReloadAttempts(reloadAttempts + 1);
            }}
          >
            <i className={`bi bi-arrow-clockwise me-2 fs-4`}></i> Reload Page...
          </button>
        )}
      </div>
    );
  }

  return (
    <section
      className="container-fluid py-4"
      style={{ backgroundColor: "#F5F5F5" }}
      id="value"
    >
      <div className="row">
        <div className="col-12">
          <div className="container">
            <div className="row g-4 align-items-stretch">
              {jobs.map((job: any, index) => (
                <div key={index} className="col-12 col-md-6 col-lg-4">
                  <Link
                    to={`https://issho.asigmagroup.com/${job.url}`}
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <OpportunityCard
                      logo={job.logo}
                      title={job.name}
                      amount="USD 32,500"
                      tags={job.sectors}
                      description={job.description}
                      deadline={job.deadline}
                      location=""
                    />
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
